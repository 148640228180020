<template src="./index.html"></template>
<style src="./index.css" scoped></style>

<script>
import {mapState,mapActions} from 'vuex'
import progressBarPopup from "@/UI/progressBarPopup";
export default {
  computed:{
    ...mapState({
      afterSaleData: (state) => state.utilities.refund_list,
      shopInfo: (state) => state.setting.get_shop_info.data,
      progressBarPopup:(state) => state.utilities.progressBarPopup,
    })
  },
  components:{
    progressBarPopup,
  },
  data() {
    return {
      tableShow:false,
      otherShow:false,//其余操作
      printShow:false,
      taskProgress:10,//进度条
      currentIndex: null,
      total: 0, //记录数
      tablbId:1,
      queryData: {
        shopName: "",
        wangWang: "",
        orderNum: "",
        name: "",
        expressNum: "",
        telephone: "",
      },
      params:{},
      orderList: {},
      shopMessage:{},
      currentPage: 1,
      offset: 0, //页码
      pageNum: 50, //每页显示几条
      totalNum: 0,
      pageShow:true
    };
  },
  mounted(){
    this.queryData.shopName = sessionStorage.getItem('shop_name')
  },
  methods: {
    ...mapActions('utilities',['getRefundList']),
    getList(){
      this.tableShow=true
      this.currentIndex = null;
      let params={
          startTime:this.getBeginTime(),
          endTime:this.getEndTime(),
          is_refund:1,
          Time:1,
          limit: this.pageNum,
          pageNum: this.offset,
      }
       params = { ...params,...this.params };
      this.getRefundList(params)
        .then((res) => {
          if(res.code==0){
            this.totalNum=res.data.total,
            this.total=res.data.total?res.data.total:'0'
            this.shopMessage=res.data.data[this.tablbId-1].order_sub[0]
            this.tablbId=res.data.data.length
            this.taskProgress = res.data.task_progress
          }else{
            this.$message.error(res.message);
          } 
        })
        .catch((error) => {});
    }, 
    toogleExpand(row, text) {
      this.tablbId=row.id
      let $table = this.$refs.table;
      if (text == "展开") {
        this.afterSaleData.map((item) => {
          if (row.id != item.id) {
            $table.toggleRowExpansion(item, false);
          }
        });
        this.currentIndex = row.id;
        this.Text = "收起";
        this.orderList=this.afterSaleData[this.tablbId-1];
      } else {
        this.currentIndex = null;
        this.Text = "展开";
      }
      $table.toggleRowExpansion(row);
    },
    
    handleSelect() {
      this.currentPage = 1
      this.offset = 1
      this.pageShow = false
     setTimeout(()=> {
        this.pageShow = true
      },0)
      this.params={
        seller_login_id:this.queryData.shopName,
        wangwang:this.queryData.wangWang,
        transaction_id:this.queryData.orderNum,
        to_full_name:this.queryData.name,
        logistics_bill_no:this.queryData.expressNum,
        to_mobile:this.queryData.telephone,
        pull_data:1
      }
      this.getList()
      
    },
     handleCurrentChange(page) {
       this.params={
        pull_data:0
      }
      this.offset = page;
      this.getList();
    },
    handleSizeChange(val) {
      this.offset=1
      this.params={
        pull_data:0
      }
      this.pageNum = val;
      this.getList();
      this.pageShow = false
      setTimeout(()=> {
        this.pageShow = true
      },0)
    },
     getBeginTime() {
      let now=new Date().getTime();
      let days = 30;
      let agoTimes = now-86400*1000*days;
      let time = new Date(agoTimes);
      var year = time.getFullYear();
      var mounth = time.getMonth() + 1;
      var date = time.getDate();
      mounth = mounth < 10 ? "0" + mounth : mounth;
      date = date < 10 ? "0" + date : date;
      return year + "-" + mounth + "-" + date + " 00:00:00";
    },
     getEndTime() {
      var time = new Date();
      var year = time.getFullYear();
      var mounth = time.getMonth() + 1;
      var date = time.getDate();
      mounth = mounth < 10 ? "0" + mounth : mounth;
      date = date < 10 ? "0" + date : date;
      return year + "-" + mounth + "-" + date + " 23:59:00";
    },
  },
  created() {
    // this.getList()
  },
};
</script>
